import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LangSwitcherComponent} from "./lang-switcher.component";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzButtonModule} from "ng-zorro-antd/button";
import {IconsProviderModule} from "../../../icons-provider.module";
import {TranslateModule} from "@ngx-translate/core";
import {MetaService} from "../../services/meta.service";

@NgModule({
  imports: [CommonModule, NzDropDownModule, NzButtonModule, IconsProviderModule, TranslateModule],
  providers: [MetaService],
  declarations: [LangSwitcherComponent],
  exports: [LangSwitcherComponent]
})
export class LangSwitcherModule {}
