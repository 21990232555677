import {AuthStateInterface} from "../interfaces/authState.interface";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppStateInterface} from "../../shared/interfaces/appState.interface";

export const authFeatureSelector = createFeatureSelector<
  AppStateInterface, //глобальный стейт
  AuthStateInterface //локальный стейт
  >('auth');

export const isSubmittingSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.isSubmitting
);

export const validationErrorsSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.validationErrors
);

export const isLoggedInSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.isLoggedIn
);

export const isAnonymousSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.isLoggedIn === false || authState.isLoggedIn === null
);

export const currentUserSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.currentUser
);

export const isLoadingSelector = createSelector(
  authFeatureSelector,
  (authState: AuthStateInterface) => authState.isLoading
);

