import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersistenceService } from './shared/services/persistence.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from './shared/interfaces/appState.interface';
import { getCurrentUserAction } from './auth/store/actions/getCurrentUser.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  constructor(
    private readonly translateService: TranslateService,
    private readonly persistenceService: PersistenceService,
    private readonly store: Store<AppStateInterface>,
  ) {
    this.store.dispatch(getCurrentUserAction());
  }

  ngOnInit(): void {
    this.translateService.use(this.persistenceService.get('lang') ?? 'ru');
  }
}
