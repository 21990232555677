import {Action, createReducer, on} from "@ngrx/store";
import {AuthStateInterface} from "../interfaces/authState.interface";
import {loginAction, loginFailureAction, loginSuccessAction} from "./actions/login.action";
import {
  getCurrentFailureAction,
  getCurrentUserAction,
  getCurrentUserSuccessAction
} from "./actions/getCurrentUser.action";
import {logoutAction, logoutSuccessAction} from "./actions/logout.action";

const initialState: AuthStateInterface = {
  isSubmitting: false,
  currentUser: null,
  isLoggedIn: false,
  validationErrors: null,
  isLoading: false
};

const Reducer = createReducer(
  initialState,
  on(loginAction, (state):AuthStateInterface => ({
    ...state,
    isSubmitting: true,
    isLoading: true,
    validationErrors: null
  })),
  on(loginSuccessAction, (state, action):AuthStateInterface => ({
    ...state,
    isSubmitting: false,
    isLoggedIn: true,
    isLoading: false,
    currentUser: action.currentUser
  })),
  on(loginFailureAction, (state,action):AuthStateInterface => ({
    ...state,
    isSubmitting: false,
    isLoading: false,
    validationErrors: action.errors
  })),
  on(getCurrentUserAction, (state, action): AuthStateInterface => ({
    ...state,
    isLoading: true,
  })),
  on(getCurrentUserSuccessAction, (state, action): AuthStateInterface => ({
    ...state,
    currentUser: action.currentUser,
    isLoading: false,
    isLoggedIn: true
  })),
  on(getCurrentFailureAction, (state, action): AuthStateInterface => ({
    ...state,
    isLoading: false,
    isLoggedIn: false,
    currentUser: null
  })),
  on(logoutAction, (state): AuthStateInterface => ({
    ...state,
    // isSubmitting: false,
    // currentUser: null,
    // isLoggedIn: false,
    // validationErrors: null,
    // isLoading: false
  })),
  on(logoutSuccessAction, (state): AuthStateInterface => ({
    ...state,
    isSubmitting: false,
    currentUser: null,
    isLoggedIn: false,
    validationErrors: null,
    isLoading: false
  })),
);


export function authReducer(state: AuthStateInterface, action: Action) {
  return Reducer(state, action);
}
