import {ActionTypes} from "../actionTypes";
import {createAction, props} from "@ngrx/store";

export const logoutAction = createAction(
  ActionTypes.LOGOUT_CURRENT_USER
);

export const logoutSuccessAction = createAction(
  ActionTypes.LOGOUT_CURRENT_USER_SUCCESS
);

