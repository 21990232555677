import {Injectable} from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {LoginRequestInterface} from "../interfaces/loginRequest.interface";
import {Observable} from "rxjs";
import {CurrentUserInterface} from "../interfaces/currentUser.interface";
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthService{
  constructor(private readonly http: HttpClient) {
  }

  public login(
    request: LoginRequestInterface
  ): Observable<CurrentUserInterface> {
    const url = `${environment.API_URL}/auth/login`;

    return this.http.post<CurrentUserInterface>(url, request);
  }

  public getCurrentUser(): Observable<CurrentUserInterface> {
    const url = `${environment.API_URL}/auth`;
    return this.http.get<CurrentUserInterface>(url);
  }

  public logOut(): Observable<void> {
    const url = `${environment.API_URL}/auth/log-out`;
    return this.http.post<void>(url, {});
  }
}
