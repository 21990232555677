import { Injectable } from '@angular/core';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCurrentFailureAction,
  getCurrentUserAction,
  getCurrentUserSuccessAction,
} from '../actions/getCurrentUser.action';
import { of } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { CurrentUserInterface } from '../../interfaces/currentUser.interface';
import { CookieService } from 'ngx-cookie';
import { logoutAction } from '../actions/logout.action';
import { Store } from '@ngrx/store';
import { PersistenceService } from '../../../shared/services/persistence.service';
import { AppStateInterface } from '../../../shared/interfaces/appState.interface';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Injectable()
export class getCurrentUserEffect {
  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUserAction),
      switchMap(() => {
        return this.authService.getCurrentUser().pipe(
          map((currentUser: CurrentUserInterface) => {
            let permissions: string[] = [];
            permissions = currentUser.user.role.permissions.map((permission) => permission.title);
            if (currentUser.user.role.all_permission) permissions.push('*');
            if (currentUser.user.organization?.products.length > 0) {
              const roles = currentUser.user.organization.products.map((product) => product.title);
              this.rolesService.flushRoles();
              roles.forEach((role) => {
                this.rolesService.addRole(role, [role]);
                permissions.push(role);
              });
            }
            if (currentUser.user.organization?.services?.length > 0) {
              currentUser.user.organization?.services.forEach((service) => {
                permissions.push(`can open ${service}`);
              });
            }
            this.permissionsService.loadPermissions(permissions);
            return getCurrentUserSuccessAction({ currentUser });
          }),
          catchError(() => {
            return of(getCurrentFailureAction());
          }),
        );
      }),
    ),
  );

  redirectIfGetCurrentUserError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getCurrentFailureAction),
        tap(() => {
          this.store.dispatch(logoutAction());
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly persistenceService: PersistenceService,
    private readonly cookieService: CookieService,
    private readonly store: Store<AppStateInterface>,
    private readonly permissionsService: NgxPermissionsService,
    private readonly rolesService: NgxRolesService,
  ) {}
}
