import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { logoutAction, logoutSuccessAction } from '../actions/logout.action';
import { PersistenceService } from '../../../shared/services/persistence.service';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class logoutEffect {
  logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() => {
        return this.authService.logOut().pipe(
          map(() => {
            return logoutSuccessAction();
          }),
          catchError(() => {
            return of(logoutSuccessAction());
          }),
        );
      }),
    ),
  );

  redirectAfterLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccessAction),
        tap(() => {
          this.cookieService.remove('retail-session');
          return this.router.navigateByUrl('/auth/login');
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly persistenceService: PersistenceService,
    private readonly cookieService: CookieService,
  ) {}
}
