<div class="container" >
  <nz-button-group>
    <button nz-button nz-dropdown [nzDropdownMenu]="menu2" class="lang_btn">
      <img src="/assets/images/lang/{{currentLocale}}.png" alt="language" class="lang_img" style='margin-right: 5px'>
      {{'locale' | translate}}
    </button>
  </nz-button-group>
  <nz-dropdown-menu #menu2="nzDropdownMenu">
    <ul nz-menu>
      <li *ngIf="currentLocale!=='ru'" nz-menu-item (click)="changeLocale('ru')">
        <img src="assets/images/lang/ru.png" alt="language" class="lang_img" style='margin-right: 5px'>
        Русский
      </li>
      <li *ngIf="currentLocale!=='uz'" nz-menu-item (click)="changeLocale('uz')">
        <img src="assets/images/lang/uz.png" alt="language" class="lang_img" style='margin-right: 5px'>
        O'zbekcha
      </li>
      <li *ngIf="currentLocale!=='en'" nz-menu-item (click)="changeLocale('en')">
        <img src="assets/images/lang/en.png" alt="language" class="lang_img" style='margin-right: 5px'>
        English
      </li>
<!--      <li *ngIf="currentLocale!=='tr'" nz-menu-item (click)="changeLocale('tr')">-->
<!--        <img src="assets/images/lang/tr.png" alt="language" class="lang_img" style='margin-right: 5px'>-->
<!--        Türkçe-->
<!--      </li>-->
    </ul>
  </nz-dropdown-menu>
</div>
