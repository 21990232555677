import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MetaService {
  constructor(private readonly title: Title, private readonly translateService: TranslateService) {}

  public setTitle(title: string, product: string = '') {
    this.translateService.get(title).subscribe((text) => {
      this.translateService.get(product).subscribe((name) => {
        if (name == 'none') this.title.setTitle(text);
        else this.title.setTitle(name + ' - ' + text);
      });
    });
  }
}
