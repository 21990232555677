import {ActionTypes} from "../actionTypes";
import {createAction, props} from "@ngrx/store";
import {CurrentUserInterface} from "../../interfaces/currentUser.interface";

export const getCurrentUserAction = createAction(
  ActionTypes.GET_CURRENT_USER
);

export const getCurrentUserSuccessAction = createAction(
  ActionTypes.GET_CURRENT_USER_SUCCESS,
  props<{currentUser: CurrentUserInterface}>()
);

export const getCurrentFailureAction = createAction(
  ActionTypes.GET_CURRENT_USER_FAILURE
);
