import {Injectable} from "@angular/core";

@Injectable()
export class PersistenceService {
  public set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to local storage', e);
    }
  }

  public get(key: string): any {
    try {
      const data = localStorage.getItem(key);
      if (data) return JSON.parse(data);
      return null;
    } catch (e) {
      console.error('Error getting data from local storage', e);
      return null;
    }
  }

  public remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error remove data from local storage', e);
    }
  }
}
