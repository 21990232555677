import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersistenceService } from '../../services/persistence.service';
import { en_US, NzI18nService, ru_RU, tr_TR } from 'ng-zorro-antd/i18n';
import { uz, ru, enUS, tr } from 'date-fns/locale';
import { _default as uz_UZ } from '../../utils/uz_UZ';

@Component({
  selector: 'mc-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.css'],
})
export class LangSwitcherComponent {
  constructor(
    private readonly translateService: TranslateService,
    private readonly persistenceService: PersistenceService,
    private readonly i18n: NzI18nService,
  ) {
    this.changeLocale(this.persistenceService.get('lang') ?? 'ru');
  }

  currentLocale: string = this.translateService.currentLang;

  public changeLocale(locale: string) {
    this.currentLocale = locale;
    this.persistenceService.set('lang', locale);
    this.translateService.use(locale);
    if (locale == 'ru') {
      this.i18n.setLocale(ru_RU);
      this.i18n.setDateLocale(ru);
    } else if (locale == 'en') {
      this.i18n.setLocale(en_US);
      this.i18n.setDateLocale(enUS);
    }
    // else if (locale == 'tr') {
    //   this.i18n.setLocale(tr_TR);
    //   this.i18n.setDateLocale(tr);
    // }
    else {
      this.i18n.setLocale(uz_UZ);
      this.i18n.setDateLocale(uz);
    }
  }
}
