import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { PersistenceService } from '../services/persistence.service';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../interfaces/appState.interface';
import { logoutAction } from '../../auth/store/actions/logout.action';
import { filter, Observable, of } from 'rxjs';
import { currentUserSelector } from '../../auth/store/selectors';
import { catchError, map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly persistenceService: PersistenceService,
    public router: Router,
    private readonly cookieService: CookieService,
    private readonly store: Store<AppStateInterface>,
    private readonly notificationService: NzNotificationService,
    private readonly translateService: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
      .pipe(select(currentUserSelector))
      .pipe(filter(Boolean))
      .pipe(
        map((user) => {
          if (user) {
            if (user.user && user.user.organization && user.user.organization.status == 2) {
              this.notificationService.error(this.translateService.instant('your organization is disabled'), '');
              this.store.dispatch(logoutAction());
              this.router.navigate(['/auth/login']);
              return false;
            }
            return true;
          } else {
            this.store.dispatch(logoutAction());
            this.router.navigate(['/auth/login']);
            return false;
          }
        }),
        catchError(() => {
          this.store.dispatch(logoutAction());
          this.router.navigate(['/auth/login']);
          return of(false);
        }),
      );
  }
}
