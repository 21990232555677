import {ActionTypes} from "../actionTypes";
import {createAction, props} from "@ngrx/store";
import {CurrentUserInterface} from "../../interfaces/currentUser.interface";
import {LoginRequestInterface} from "../../interfaces/loginRequest.interface";
import {BackendErrorResponseInterface} from "../../../shared/interfaces/backendErrorResponse.interface";

export const loginAction = createAction(
  ActionTypes.LOGIN,
  props<{ request: LoginRequestInterface }>()
);

export const loginSuccessAction = createAction(
  ActionTypes.LOGIN_SUCCESS,
  props<{currentUser: CurrentUserInterface}>()
);

export const loginFailureAction = createAction(
  ActionTypes.LOGIN_FAILURE,
  props<{ errors: BackendErrorResponseInterface }>()
);
